<template>
  <div class="logged-box">
    <div class="logged">
      <div class="logo">
        <img src="./img/logo.svg" />
      </div>
      <div class="select-user">
        <h2>
          <!-- 选择用户名 -->
          {{ $t('c2222') }}          
        </h2>
      </div>
      <el-card class="box-card">
        <div class="box-content">
          <el-avatar icon="el-icon-user-solid" class="mr10"></el-avatar>
          <div class="circle mr10"></div>
          <div class="username mr10" @click="toAuth">
            {{ username }}
          </div>
        </div>
      </el-card>
      <div class="otherUser">
        <h3 @click="otherUserLogin">
          <!-- 使用其他用户名登录 -->
          {{ $t('c2223') }}
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
// 已有用户页面
import * as thirdLoginAuthApi from "../api";
export default {
  name: "logged",
  data() {
    return {
      username: "aaaaa@cloudcc.com",
    };
  },
  mounted() {
    this.username =
      this.$CCDK.CCUser.getUserInfo().loginName || this.$route.query.username;
    // 隐藏加载框
    document.getElementById("lightning-loading").style.display = "none";
  },
  methods: {
    /**
     * 对象转formdata格式 方法
     * @param  obj 对象数据
     * @returns  转为formdata格式的数据
     */
    jsToFormData(obj) {
      const formData = new FormData();
      Object.keys(obj).forEach((key) => {
        if (obj[key] instanceof Array) {
          obj[key].forEach((item) => {
            formData.append(key, item);
          });
          return;
        }
        formData.append(key, obj[key]);
      });
      return formData;
    },
    // 其他用户登录
    otherUserLogin() {
      this.$router.push({
        path: "/thirdLogin",
        query: {
          state: this.$route.query.state,
          orgId: this.$route.query.orgId || ""
        },
      });
    },
    // toAuth 点击现有用户  根据公司业务token去请求接口 判断此用户有没有授权 以及 获取现有用户的第三方token、state等
    // 如果已授权 直接到第三方   没有授权 携带接口返回的专用token  state到授权页面
    toAuth() {
      let params = {
        accessToken: this.$route.query.token,
        state: this.$route.query.state,
        isMd5: "false",
        language: localStorage.getItem("locale"),
        orgId: this.$route.query.orgId || ""
      };
      thirdLoginAuthApi
        .thirdPartyLoginAuth(this.jsToFormData(params))
        .then((res) => {
          if (res.data != null) {
            // 没授权
            if (res.data.result) {
              this.$router.push({
                path: "/thirdAuthorization",
                query: {
                  state: res.data.data.state,
                  token: res.data.data.accessToken,
                  username: res.data.data.username,
                  orgId: this.$route.query.orgId || ""
                },
              });
            } else {
              this.$message.error(res.data.returnInfo);
            }
          } else {
            // data中没有返回数据  返回数据在headers中
            // 授权了
            let headers = res.headers;
            if (headers.enableredirect == "true") {
              window.location.href = `${headers.url}?state=${headers.state}&code=${headers.code}`;
            }
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.logged-box {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .logged {
    width: 400px;
    .logo {
      width: 100%;
      display: flex;
      justify-content: center;
      img {
        width: 300px;
      }
    }
    .select-user {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .box-card {
      width: 100%;
      .box-content {
        display: flex;
        align-items: center;
        .circle {
          width: 10px;
          height: 10px;
          background: #67c23a;
          border-radius: 5px;
        }
        .username {
          cursor: pointer;
        }
      }
    }
    .otherUser {
      width: 100%;
      color: #409eff;
      cursor: pointer;
      margin-top: 30px;
      text-align: center;
    }
  }
  .mr10 {
    margin-right: 10px;
  }
}
</style>
